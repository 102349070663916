@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_language-picker
Title: Language Picker
Descr: A custom selector allowing users to choose their preferred language on a page
Usage: https://codyhouse.co/ds/components/info/language-picker

default
  --flags
  --hide-label
-------------------------------- */
/* OVERRIDE GOOGLE TRANSLATE WIDGET CSS BEGIN */

  
/* HIDE the google translate toolbar */
.skiptranslate {
  display: none !important;
  border: none;
  box-shadow: 0 0;
  -webkit-box-shadow: 0 0;
}
  
body {
  top: 0px !important;
}
  
/* OVERRIDE GOOGLE TRANSLATE WIDGET CSS END */
  
:root {
    --picker-vertical-gap: 4px;
}
  
.language-picker {
  $this: &;
  display: inline-block;
  position: relative;
  &__form { // replace the default form with a custom dropdown
      display: none;
  }
  &__button { // created in js - dropdown trigger. Pass custom classes using the data-trigger-class attribute of the .language-picker element
      .icon { // dropdown arrow (edit inline SVG in JS file of component)
          height: 16px;
          width: 16px;
          &:first-of-type {
            margin-right: var(--space-3xs);
          }
          &:last-of-type {
            margin-left: var(--space-3xs);
          }
      }
  }
  &__dropdown { // created in js - dropdown element with list of languages 
      position: absolute;
      left: 0;
      top: 100%;
      width: 200px;
      background-color: var(--color-bg-light);
      box-shadow: var(--inner-glow), var(--shadow-md);
      padding: var(--space-3xs) 0;
      border-radius: var(--radius-md);
      z-index: var(--z-index-popover, 5); 
      margin-top: var(--picker-vertical-gap);
      margin-bottom: var(--picker-vertical-gap);
      font-size: 1rem;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s .2s, opacity .2s;
      &--right { // change dropdown position based on the available space
          right: 0;
          left: auto;
      }
      &--up {
          bottom: 100%;
          top: auto;
      }
      #{$this}__list{
        li {
          margin: 0;
        }
      }
  }
  &__button[aria-expanded="true"] + &__dropdown {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity .2s;
  }
  &__item {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: var(--space-2xs) var(--space-sm);
      color: var(--color-contrast-high);
      transition: background .2s;
      span { // truncate text
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
      }
      &:hover {
          background-color: alpha(var(--color-contrast-higher), 0.075);
      }
      &:focus {
          outline: none;
          background-color: alpha(var(--color-primary), 0.1);
      }
      &[aria-selected=true] { // selected language
          position: relative;
          background-color: var(--color-primary);
          color: var(--color-white);
          &:focus {
              outline: 2px solid alpha(var(--color-primary), 0.2);
          }
          &::after { // check icon next to the selected language
              content: '';
              height: 16px;
              width: 16px;
              background-color: currentColor;
              mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpolyline stroke-width='2' stroke='%23ffffff' fill='none' stroke-linecap='round' stroke-linejoin='round' points='1,9 5,13 15,3 '/%3E%3C/svg%3E");
              margin-left: auto;
          }
      }
  }
  &__flag { // create the icon flag element
      display: flex;
      align-items: center;
      &::before { // flag icon - hide on default version
          display: none;
          flex-shrink: 0;
          content: '';
          height: 16px;
          width: 24px;
          margin-right: var(--space-2xs);
          // set flag as bg image
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
      }
      #{$this}__dropdown &::before { // flag inside dropdown
          margin-right: var(--space-xs);
      }
  }
  // --flags
  &--flags {
      #{$this}__flag::before { // show flag
          display: inline-block; // flex fallback
          display: inline-flex;
      }
      #{$this}__button .icon:first-of-type {
          display: none; // hide globe icon
      }
  }
  // --hide-label
  &--hide-label {
      #{$this}__button {// hide language label in custom button -> show only flag and arrow icon
          .icon {
              margin-left: 0;
              &:first-of-type {
                  display: none;
              }
          }
          em { // label
              display: none;
          }
          #{$this}__flag::before {
              margin-right: var(--space-3xs);
          }
      }
      #{$this}__flag::before { // show flag
          display: inline-block; // flex fallback
          display: inline-flex;
      }
  }
}