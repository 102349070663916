@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_foodnotes
Title: 
Descr: 
Usage: https://codyhouse.co/ds/components/app/footnotes

-------------------------------- */

.footnote {
    font-size: var(--text-xs);
    vertical-align:super;
    color: var(--color-contrast-medium); //var(--md-default-fg-color--light);
    &::before {
        content: "[";
    }
    &::after{
        content: "]";
    }
}
.footnotes {
    font-size: var(--text-xs);
    border-top:1px solid var(--color-contrast-lower);
    ol {
        list-style: none;
        counter-reset: fn-count;
        padding-left: 0;
        li {
            position: relative;
            counter-increment: fn-count;
            &::before {
                content: "[" counter(fn-count) "]";
                position: absolute;
                left:0;
                vertical-align:super;
            }
            padding-inline-start: 1.5em !important;
        }
    }
}