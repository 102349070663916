@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_list
Title: List
Descr: Custom list component
Usage: https://codyhouse.co/ds/components/info/list

-------------------------------- */

:root {
  --list-space-y: 0.375em; // vertical gaps
  --list-offset: 1em;  // sublist horizontal offset
  --list-line-height-multiplier: 1; // line-height multiplier
}

.list, .text-component .list {
    padding-left: 0;
    list-style: none;
    ul, 
    ol {
        list-style: none;
        margin: 0; // reset
        margin-top: calc((var(--list-space-y) / 2));
        padding-top: calc((var(--list-space-y) / 2));
        padding-left: var(--list-offset);
    }
    li {
        padding-bottom: calc((var(--list-space-y) / 2));
        margin-bottom: calc((var(--list-space-y) / 2));
        line-height: calc(1.4 * var(--list-line-height-multiplier));
    }
    > li:last-child, 
    ul > li:last-child, 
    ol > li:last-child {
        margin-bottom: 0;
    }
    &:not(.list--border) > li:last-child, 
    ul > li:last-child, ol > li:last-child {
        padding-bottom: 0;
    }
}

/* #region (ul + ol) */
.list--ul, .text-component .list--ul, 
.list--ol, .text-component .list--ol {
    --list-offset: calc(var(--list-bullet-size) + var(--list-bullet-margin-right));
    ul, ol {
        padding-left: 0;
    }
    li {
        @supports (--css: variables) {
            padding-left: var(--list-offset) !important;
        }
    }
    li::before {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        position: relative;
        top: -0.1em;    
        @supports (--css: variables) {
            width: var(--list-bullet-size) !important; 
            height: var(--list-bullet-size) !important;
            margin-left: calc(var(--list-bullet-size) * -1) !important;
            left: calc(var(--list-bullet-margin-right) * -1) !important;
        }
    }
}

// unordered list
.list--ul, .text-component .list--ul { 
    --list-bullet-size: 7px; // dot width and height
    --list-bullet-margin-right: 12px; // gap between bullet and content
    > li {
        padding-left: 19px; // IE fallback
    }
    > li::before { // bullet
        content: '';
        border-radius: 50%;
        color: var(--color-contrast-lower); // bullet color
        background-color: currentColor;
        // IE fallback
        width: 7px;
        height: 7px;
        margin-left: -7px;
        left: -12px;
        // end - IE fallback
    }
    ul li::before {
        background-color: transparent;
        box-shadow: inset 0 0 0 2px currentColor;
    }
}

// ordered list
.list--ol, .text-component .list--ol { 
    --list-bullet-size: 26px; // ⚠️ use px or rem units - circle width and height
    --list-bullet-margin-right: 6px; // ⚠️ use px or rem units - gap between circle and content
    --list-bullet-font-size: 14px; // ⚠️ use px or rem units - bullet font size
    counter-reset: list-items;
    > li {
        counter-increment: list-items;
        padding-left: 32px; // IE fallback
    }
    ol {
        counter-reset: list-items;
    }
    > li::before {
        content: counter(list-items);
        font-size: var(--list-bullet-font-size, 14px);
        background-color: var(--color-contrast-lower);
        color: var(--color-contrast-high);
        line-height: 1;
        border-radius: 50%;
        // IE fallback
        width: 26px;
        height: 26px;
        margin-left: -26px;
        left: -6px;
        // end - IE fallback
    }
    ol > li::before {
        background-color: transparent;
        box-shadow: inset 0 0 0 2px var(--color-contrast-lower);
    }
}
/* #endregion */

/* #region (border) */
.list--border, .text-component .list--border { // show border divider among list items
    li:not(:last-child) {
        border-bottom: 1px solid var(--color-contrast-lower);
    }
    ul, ol {
        border-top: 1px solid var(--color-contrast-lower);
    }
}
/* #endregion */

/* #region (icons) */
.list--icons, .text-component .list--icons { // use icons as bullet points
    --list-bullet-size: 24px;
    --list-bullet-margin-right: 8px; // gap between icon and text
    --list-offset: calc(var(--list-bullet-size) + var(--list-bullet-margin-right));
    ul, ol {
        padding-left: 32px; // IE fallback
        @supports (--css: variables) {
            padding-left: var(--list-offset);
        }
    }
}
.list__icon {
    position: relative;
    // IE fallback
    width: 24px;
    height: 24px;
    margin-right: 8px;
    &:not(.top-0) {
        top: calc((1em * 1.4 - 24px) / 2);
    }
    // end - IE fallback
    @supports (--css: variables) {
        width: var(--list-bullet-size);
        height: var(--list-bullet-size);
        margin-right: var(--list-bullet-margin-right);
        &:not(.top-0) {
            top: calc((1em * 1.4 * var(--list-line-height-multiplier) - var(--list-bullet-size)) / 2);
        }
    }
}
/* #endregion */