@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* --------------------------------

File#: _1_table-of-contents
Title: Table of Contents
Descr: A navigation with a list of links to the main sections of the page.
Usage: https://codyhouse.co/ds/components/info/table-of-contents
Dependencies:
    _1_smooth-scrooling

-------------------------------- */
html {
    scroll-behavior:smooth;
}
@mixin leftPosition($startingValue: 2.1, $increment: .5, $max: 6) {
    $selector: '';
    @for $i from 0 through $max - 1 {
        $selector: $selector + ' .toc__list';
        #{$selector} {
            .toc__link {
                padding:0;
                margin:0;
                padding-left: calc(var(--space-sm) * #{$startingValue + $i * $increment});
                font-size:calc(var(--text-sm) * (2/#{$startingValue + $i * $increment}));
            }
        }
    }
}
:root {
    --toc-border-width:1px /* static version only */
}

.toc{
    $this: &;
    &__list {
        position:relative;
        @include leftPosition();
    }
    &__link, 
    &__label {
        padding:var(--space-3xs) var(--space-sm);
    }
    &__link {
        position: relative;
        color: var(--color-contrast-medium);
        text-decoration:none;
        background: none;
        &::before {
            content: "";
            width: var(--toc-border-width);
            height: 100%;
            position: absolute;
            left: 0;
            top:0;
        }
        &:hover {
            color:var(--color-contrast-high);
        }
        /*~ #{$this}__list{
            will-change: height;
            transition: all .3 var(--ease-in-out);
            display:none;
        }*/
        &--selected {
            color:var(--color-primary);
            &:hover {
                color:var(--color-primary);
            }
            /*~ #{$this}__list{
                display:block;
            }*/
        }
    }
    &__label {
        text-transform: uppercase;
        letter-spacing: .1em;
        font-size:var(--text-sm);
    }
    &__control {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content:space-between;
        &-text {
            position:relative;
            > * {
                display: inline-block;
                transition:opacity .4s, transform .4s var(--ease-out);
                &:last-child {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    pointer-events: none;
                    transform:translateY(-10px);
                }
            }
        }
    }
    &__icon-arrow {
        .icon__group {
            will-change: transform;
            transform-origin: 8px 8px;
            transition:transform .3s var(--ease-out);
            > * {
                transform-origin: 8px 8px;
                stroke-dasharray: 17;
                transform: translateY(3px);
                transition: transform .3s, stroke-dashoffset .3s;
                transition-timing-function:var(--ease-out);
                &:first-child {
                    stroke-dashoffset:10;
                }
                &:last-child {
                    stroke-dashoffset:10;
                }
            }
        }
    }
    &-content__target {
        scroll-margin-top:var(--space-4xs);
    }
    &:not(&--static) {
        border-radius: var(--radius-md);
        background-color: var(--color-bg-light);
        box-shadow: var(--inner-glow), var(--shadow-sm);
        transition: .3s;
        &:hover {
            box-shadow:var(--inner-glow), var(--shadow-md);
        }
        #{$this}__nav {
            display: none;
            margin:var(--space-2xs) 0;
        }
        #{$this}__list {
            padding-bottom:var(--space-xs);
        }
        #{$this}__label {
            display:none;
        }
        #{$this}__link {
            display:flex;
        }
        &#{$this}--expanded{
            #{$this}__control-text > * {
                &:first-child {
                    transform: translateY(10px);
                    opacity: 0;
                    pointer-events:none;
                }
                &:last-child {
                    opacity: 1;
                    pointer-events: auto;
                    transform:translateY(0);
                }
            }
            #{$this}__nav {
                display: block;
                animation:toc-entry-animation .4s var(--ease-out);
            }
            #{$this}__icon-arrow{
                .icon__group {
                    transform:rotate(-90deg);
                    > * {
                    &:first-child,
                    &:last-child {
                            stroke-dashoffset: 0;
                            transform:translateY(0px);
                    }
                    }
                }
            }
        }
    }
    &--static {
        box-shadow:inset var(--toc-border-width) 0 0 var(--color-contrast-lower);
        &[class *="position-sticky"] {
            @include sticky-panel;
        }
        #{$this}__label {
            font-size:var(--text-xs);
        }
        #{$this}__link {
            display: inline-flex;
            font-size:var(--text-sm);
            &--selected::before {
                background-color:var(--color-primary);
            }
        }
        #{$this}__control {
            display:none;
        }
        #{$this}-content--toc-static {
            *:target {
                animation:toc-target 2s;
            }
        }
    }
    /*&__link {
        ~ #{$this}__list{
            will-change: height;
            transition: height .3s var(--ease-in-out);
            display:none;
        }
        &--selected {
            ~ #{$this}__list {
                display:block;
            }
        }
    }*/
}

@keyframes toc-target {
    0%, 50% {
        outline:2px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.5)
    }

    100% {
        outline:2px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0)
    }
}

@keyframes toc-entry-animation {
    from {
        opacity: 0;
        transform:translateY(-10px)
    }

    to {
        opacity: 1;
        transform:translateY(0px)
    }
}

    [class*="toc--static"]::before {
    display: none;
    content: "collapsed";
}

@include min-width(xs) {
    .toc--static\@xs::before {
        content: "static";
    }
}

@include min-width(sm) {
    .toc--static\@sm::before {
        content: "static";
    }
}

@include min-width(md) {
    .toc--static\@md::before {
        content: "static";
    }
}

@include min-width(lg) {
    .toc--static\@lg::before {
        content: "static";
    }
}

@include min-width(xl) {
    .toc--static\@xl::before {
        content: "static";
    }
}
