@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_switch
Title: Switch
Descr: Custom ON/OFF checkbox toggle
https://codyhouse.co/ds/components/info/switch

-------------------------------- */

:root {
    --switch-width: 64px;
    --switch-height: 32px;
    --switch-padding: 3px;
    --switch-animation-duration:0.2s;
}

.switch {
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    width: var(--switch-width);
    height: var(--switch-height);
    border-radius: 50em;
    padding:var(--switch-padding) 0;
    &__input,
    &__label {
        position: absolute;
        left: 0;
        top:0;
    }
    &__input {
        margin: 0;
        padding: 0;
        opacity: 0;
        height: 0;
        width: 0;
        pointer-events:none;
    }
    &__input:checked + &__label {
        background-color:var(--color-primary);
    }
    
    &__input:checked + &__label + &__marker {
        left:calc(100% - var(--switch-height) + var(--switch-padding));
    }
    &__input:focus + &__label, 
    &__input:active + &__label {
        box-shadow:0 0 0 2px hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.2);
    }
    &__input:checked:focus + &__label, 
    &__input:checked:active + &__label {
        box-shadow:0 0 0 2px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
    }
    &__label {
        width: 100%;
        height: 100%;
        color: transparent;
        -webkit-user-select: none;
        user-select: none;
        background-color: var(--color-bg-darker);
        border-radius: inherit;
        z-index: 1;
        transition: var(--switch-animation-duration);
        overflow:hidden;
    }
    &__marker {
        position: relative;
        background-color: var(--color-white);
        width: calc(var(--switch-height) - var(--switch-padding) * 2);
        height: calc(var(--switch-height) - var(--switch-padding) * 2);
        border-radius: 50%;
        z-index: 2;
        pointer-events: none;
        box-shadow: var(--shadow-xs);
        left: var(--switch-padding);
        transition: left var(--switch-animation-duration);
        will-change: left;
    }
}
