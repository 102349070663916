@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _2_settings-table
Title: Settings Table
Descr: A table containing a list of options that can be enabled/disabled.
Usage: https://codyhouse.co/ds/components/info/settings-table
Dependencies
    Table v2
    Switch

-------------------------------- */

.settings-tbl {
    .switch {
        --switch-width: 48px;
        --switch-height: 24px;
        --switch-padding: 2px;
        --switch-animation-duration: 0.2s;
    }
}