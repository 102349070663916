@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_hero
Title: Hero
Descr: A full-width callout section
Usage: https://codyhouse.co/ds/components/info/hero

-------------------------------- */

.hero {
    display: block;
    position: relative;
    height: 100vh; 
    transition: height 0.2s;
    .hero-img {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        & > *:first-child {
            height: 100%;
            max-width: 1000%;
            background-size: cover;
            background-position: center center;
            overflow: hidden;
        }
        & > video {
            width: 100vw;
            object-fit: cover;
        }
        & > img.img-placeholder {
            display: none;
        }
    }
    .hero-image {
        min-height: calc(100vh * 1.5); 
        background-attachment: fixed;
        background-repeat: no-repeat;
    } 
    .hero-inner{
        display: flex;
        position: relative;
        height: 100%;
        flex-direction: row;
        flex-wrap: wrap-reverse;
        justify-content: center;
        align-items: flex-end;
        align-content: flex-start;
        margin-left: auto;
        margin-right: auto;
    }
    &--overlay-layer {
        &::after { /* add a layer in between the img/video background and the content */
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: alpha(var(--color-bg), 0.65);
            z-index: 1;
        }
    }
}
/* Turn off parallax scrolling for all tablets and phones. Increase/decrease the pixels if needed */
/* @media only screen and (max-device-width: 1366px) {
    .hero-parallax {
        background-attachment: scroll;
    }
} 
main:not(.hero-parallax) {
    position: relative;
}*/