@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_main-footer
Title: Main Footer
Descr: Footer navigation
Usage: https://codyhouse.co/ds/components/info/main-footer

-------------------------------- */

.main-footer {
    @include clean-a;
    &__logo {
        display: inline-block;
        svg, img {
            display: block;
        }
    }
    &__link {
        color: var(--color-contrast-medium);
        border-bottom: none;
        &:hover {
            color: var(--color-contrast-high);
        }
    }
    &__social {
            text-decoration: none;
            display: inline-block;
            color: var(--color-contrast-medium);
        &.tooltip-trigger { /* style inline-text tooltip trigger */
            white-space: nowrap;
            border-bottom: none;
            cursor: help;
        }
        &:hover {
            color: var(--color-contrast-high);
        }
        .icon {
            font-size: 1.2em; // icon size
        }
        @include min-width(md) {
            & {
                font-size: 1em;
            }
        }
    }
}
.copyleft {
    display: inline-block;
    transform: rotate(180deg);
}
