@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_table
Title: Table
Descr: Overrides Data tables used to organize and display information in rows and columns 
    as explained on https://codyhouse.co/ds/components/info/table

-------------------------------- */
@use '../config' as *;

// >>> style affecting all (block + expanded) versions 👇
.table {
    position: relative;
    z-index: 1;
  }
  // <<< end style affecting all versions
  
  // >>> block version only (mobile) 👇
  .table:not(.table--expanded) {
    border-collapse: separate;
    border-spacing: 0 var(--space-md); // row gap
    margin-top: calc(-2 * var(--space-md)); // set spacing variable = row gap ☝️
  
    .table__header { // hide table header - but keep it accessible to SR
      position: absolute;
      clip: rect(1px, 1px, 1px, 1px);
      clip-path: inset(50%);
    }
  
    .table__row {
      .table__cell:first-child {
        border-radius: var(--radius-md) var(--radius-md) 0 0;
      }
    
      .table__cell:last-child {
        border-radius: 0 0 var(--radius-md) var(--radius-md);
        
        &::after { // hide border bottom
          display: none;
        }
      }
    }
  
    .table__cell {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      text-align: right;
      padding: var(--space-md);
      background-color: var(--color-bg-light);
  
      &::after { // border bottom
        content: '';
        position: absolute;
        bottom: 0;
        left: var(--space-md);
        width: calc(100% - (2 * var(--space-md)));
        height: 1px;
        background-color: var(--color-contrast-lower);
      }
    }
  
    .table__label { // inline labels -> visible when table header is hidden
      font-weight: bold;
      text-align: left;
      color: var(--color-contrast-higher);
      margin-right: var(--space-md);
    }
  }
  // <<< end block version
  
  // >>> expanded version only (desktop) 👇 -> show standard rows and cols
  .table--expanded {
    border-bottom: 1px solid var(--color-contrast-lower); // table border bottom
  
    .table__header {
      .table__cell { // header cell style
        position: relative;
        z-index: 10;
        background-color: var(--color-bg);
        border-bottom: 1px solid var(--color-contrast-lower); // header border bottom
        font-weight: bold;
        color: var(--color-contrast-higher);
      }
    }
  
    .table__body {
      .table__row {
        &:nth-child(odd) {
          background-color: alpha(var(--color-bg-dark), 0.85);
        }
      }
    }
  
    .table__cell {
      padding: var(--space-sm);
    }
  
    .table__label { // hide inline labels
      display: none;
    }
  
    // --header-sticky
    .table__header--sticky {
      .table__cell { // header cell style
        position: sticky;
        top: 0;
      }
    }
  }
  // <<< end expanded version
  
  .table {
    opacity: 0; // hide table while it is initialized in JS
  }
  
  .table--loaded {
    opacity: 1;
  }
  
  // detect when the table needs to switch from the mobile layout to an expanded one - used in JS
  [class*="table--expanded"]::before {
    display: none;
  }
  
  @each $breakpoint, $value in $breakpoints {
    .table--expanded\@#{$breakpoint}::before {
      content: 'collapsed';
      @include min-width(#{$breakpoint}) {
        content: 'expanded';
      }
    }
  }



// un dels meus amb més info https://codepen.io/cuquet/pen/KKrOLEP?editors=0010
/* table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;
    //white-space: nowrap;
    font-size: var(--text-sm);
    td, th {
        padding: var(--space-sm);
        vertical-align: top;
        &:not([align]) {
            text-align: inherit;
        }
    }
    position: relative;
    z-index: 1;
    @include max-width(md) {
        border-collapse: separate;
        border-spacing: 0 var(--space-md); // row gap
        margin-top: calc(-2 * var(--space-sm)); // set spacing variable = row gap
        thead,
        tbody,
        tfoot,
        th,
        td,
        tr {
            display: block;
        }
        thead {
            visibility: hidden;
            display:none;
            height: 0;
            position: absolute;
        }
        tr {
            margin-bottom: var(--space-2xs);
            td {
                &:first-child {
                    border-radius: var(--radius-md) var(--radius-md) 0 0;
                }
                &:last-child {
                    border-radius: 0 0 var(--radius-md) var(--radius-md);
                    &::after { 
                        display: none;
                    }
                }
            }
        }
        td {
            position: relative;
            font-size: var(--text-xs);
            text-align: right !important;
            padding: var(--space-sm);
            display: flex;
            justify-content: space-between;
            width: 100%;
            background-color: var(--color-bg-light);
            &::before {
                content: attr(data-header)": ";
                float: left;
                color: var(--color-contrast-higher);
                font-weight: bold;
                margin-right: var(--space-md);
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: var(--space-sm);
                width: calc(100% - (2 * var(--space-sm)));
                height: 1px;
                background-color: var(--color-contrast-lower);
              }
        }
        tbody{
            tr { 
                &:last-child {
                    border-bottom: 0 !important;
                }
            }
        } 
        tfoot {
            tr {
                td {
                    &::before, &::after {
                        content:none;
                    }
                    font-size: var(--text-xs) !important;
                    padding: var(--space-3xs) !important;
                }
            }
        }
    }
    thead{
        th{
            position: relative;
            z-index: 10;
            font-size: var(--text-base);
            color: var(--color-contrast-higher);
            background-color: var(--color-bg);
            border-bottom: 1px solid var(--color-contrast-lower); 
        }
    }
    tbody{
        tr{ 
             &:nth-child(odd) {
                background-color: alpha(var(--color-bg-dark), 0.85);
            }
            &:last-child {
                border-bottom: 1px solid var(--color-contrast-lower);
            }
        }
    }
    tfoot {
        tr {
            td {
                background-color: var(--color-bg);
                text-align: left !important;
                font-size: var(--text-xs);
                padding: var(--space-2xs);
            }
        }
    }
}*/

.table-ovf-x {
    position: relative;
    z-index: 1;
    td, th {
        border-right: 1px solid var(--color-contrast-lower);
    }
    @include max-width(md) {
        border-collapse: collapse;
        border-spacing: 0;
        display: block;
        margin-top: calc(-1 * var(--space-xs)); 
        thead {
            visibility: visible;
            display:block;
            height: auto;
            position: relative;
            float: left;
            border-right: 1px solid var(--color-contrast-lower);
            th {
                border-bottom: 1px solid var(--color-contrast-lower);
            }
        }
        tbody {
            width: auto;
            position: relative;
            overflow-x: auto;
        }
        td, th {
            text-align: left !important;
            padding: var(--space-xs);
            vertical-align: middle;
            box-sizing: border-box;
            overflow-x: hidden;
            overflow-y: auto;
            font-size:var(--text-xs) !important;
            text-overflow: ellipsis;
        }
        tbody {
            tr {
                display: table-cell;
                td {
                    border-bottom: 1px solid var(--color-contrast-lower);
                    border-right: 1px solid var(--color-contrast-lower);
                    border-radius: 0;
                    // https://developer.mozilla.org/es/docs/Web/CSS/:nth-child
                    &:nth-child(odd) {
                        background-color:alpha(var(--color-bg-dark), 0.85);
                    }
                    &:nth-child(even) {
                        background: var(--color-bg);
                    }
                    &::before, &::after {
                        content: none;
                    }
                }
            }
        }
    }
    thead {
        th {
            position: relative;
            z-index: 10;
            font-size: var(--text-base);
            font-weight: bold;
            color: var(--color-contrast-higher);
            background-color: var(--color-bg);
            border-bottom: 1px solid var(--color-contrast-lower);
            border-radius: 0;
        }
    }
    tbody {
        tr { 
            &:nth-child(odd) {
                background-color:alpha(var(--color-bg-dark), 0.85);
            }
            &:nth-child(even) {
                background: var(--color-bg);

            }
            &:last-child {
                border-bottom: 1px solid var(--color-contrast-lower);
            }
        }
    }
    tfoot {
        tr {
            td {
                background-color: var(--color-bg);
                text-align: left !important;
                font-size: var(--text-xs);
                padding: var(--space-2xs);
                border-radius: 0;
            }
        }
    }
}