
@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _3_light-dark-switch
Title: Light Dark Switch
Descr: A color theme switcher based on the user system preferences.
Usage: https://codyhouse.co/ds/components/info/light-dark-switch

Dependencies
    _1_popover
    _2_adv-custom-select
-------------------------------- */

.ld-switch-btn {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    display: inline-block;
    transition:opacity .2s, color .2s
}

.ld-switch-btn:hover {
    cursor: pointer;
    opacity:.8
}

.ld-switch-btn:focus {
    outline: none;
    color:var(--color-primary)
}

.ld-switch-btn.popover-control--active {
    color:var(--color-primary)
}

.ld-switch-btn__icon-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform:translateY(100%) rotate(35deg) scale(.5)
}

.ld-switch-btn__icon-wrapper--in {
    opacity: 1;
    transform:translateY(0) rotate(0) scale(1)
}

.ld-switch-btn__icon-wrapper--out {
    opacity: 0;
    transform:translateY(-100%) rotate(-35deg) scale(.5)
}

.ld-switch-btn__icon-wrapper--in, .ld-switch-btn__icon-wrapper--out {
    transition:transform .3s var(--ease-in-out), opacity .3s
}

.ld-switch-btn__icon {
    margin: auto;
    --size:20px /* icon size */
}

.popover.ld-switch-popover {
    --popover-width:250px
}

.ld-switch-popover__option {
    -webkit-user-select: none;
    user-select: none;
    transition:opacity .2s
}

.ld-switch-popover__option:hover {
    cursor: pointer;
    opacity:.85
}

.ld-switch-popover__option:focus {
    outline:none
}

.ld-switch-popover__option:focus figure {
    box-shadow:0 0 0 1px var(--color-bg-light), 0 0 0 3px var(--color-contrast-higher)
}

.ld-switch-popover__option[aria-selected=true] {
    color:var(--color-primary)
}

.ld-switch-popover__option[aria-selected=true] figure {
    box-shadow: 0 0 0 1px var(--color-bg-light), 0 0 0 3px currentColor
}

[data-theme="dark"] {
    .light{
        display:none;
    }
}
[data-theme="light"], [data-theme="default"] {
    .dark{
        display:none;
    }
}