@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_404
Title: 404
Descr: 404 error section
Usage: https://codyhouse.co/ds/components/info/404

-------------------------------- */

.fof {
  margin-top: calc(var(--f-header-height) + var(--space-4xs) / 2 );
}

.fof__animation {
  svg {
    display: block;
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
  }
}

#i-fof-browser {
  transform-origin: 260px 304px;
  animation: i-fof-browser 4s infinite;
}

#i-fof-shadow {
  transform-origin: 282px 410px;
  animation: i-fof-shadow 4s infinite;
}

@keyframes i-fof-browser {
  0%, 100% {
    transform: translateY(0) scale(1);
  }

  50% {
    transform: translateY(-10%) scale(0.9);
  }
}

@keyframes i-fof-shadow {
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }
}