@use 'config' as *;
// --- 👆 update this path if you're importing CodyFrame as npm module
// e.g., '../../../node_modules/codyframe/main/scss/config'

.form {
  &-control {
      $this: &;
      background: var(--color-bg-dark);
      box-shadow: inset 0 0 0 1px var(--color-contrast-lower);
      padding: var(--space-2xs) var(--space-xs);
      border-radius: var(--radius-md);
      font-size: 1em;
      line-height: 1.2;
      transition: 0.2s;
      &::placeholder,
      &::-moz-placeholder {
        opacity: 1;  
        color: var(--color-contrast-low);
      }
      &:focus, &:focus-within {
        background: var(--color-bg);
        outline: none;
        box-shadow: inset 0 0 0 1px alpha(var(--color-contrast-lower), 0), 
                    0 0 0 2px var(--color-primary), 
                    var(--shadow-sm);
      }
      /* disabled */
      &--disabled, 
      &[disabled], 
      &[readonly] {
          opacity: 0.5;
          cursor: not-allowed;
      }
      &[aria-invalid="true"], 
      &#{$this}--error {
          box-shadow: inset 0 0 0 1px alpha(var(--color-contrast-lower), 0), 
                      0 0 0 2px var(--color-error);
          
          &:focus, &:focus-within {
            box-shadow: inset 0 0 0 1px alpha(var(--color-contrast-lower), 0), 
                        0 0 0 2px var(--color-error), 
                        var(--shadow-sm);
          }
      }
  }
  /* legend */
  &-legend {
      font-weight: 700;
      color: var(--color-contrast-higher);
      line-height: 1.2;
      font-size: var(--text-md);
      margin-bottom: var(--space-md);
  }
  &-label {
      display: inline-block;
      font-size: var(--text-sm);
      margin-bottom: var(--space-3xs);
  }
}