@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_article-gallery-v3
Title: 
Descr: 
Usage: https://codyhouse.co/ds/components/info/article-gallery-v3

-------------------------------- */
.posts {
    &__img {
        display: block;
        transition: opacity .3s;
        border-radius: var(--radius-md);
        overflow: hidden;
        img {
            display: block;
            width: 100%;
        }
        &:hover {
            opacity: 0.85;
        }
    }
    &__headline {
        font-size: var(--text-xl);
        a {
            color: var(--color-contrast-higher);
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__author {
        display: grid;
        grid-template-columns: 3em 1fr;
        grid-gap: var(--space-xs);
        align-items: center;
        margin-top: var(--space-md);
        &-img {
            display: block;
            width: 3em;
            height: 3em;
            border-radius: 50%;
            overflow: hidden;
            transition: transform .3s var(--ease-out-back);
            img {
                display: block;
                width: inherit;
                height: inherit;
            }
            &:hover {
                transform: scale(1.1);
            }
        }
        &-name {
            font-weight: bold;
            color: var(--color-contrast-higher);
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    ul > li {
        &:not(:last-child) {
            padding-bottom: var(--space-sm);
            border-bottom: 1px solid var(--color-contrast-lower);
        }
    }
    .post-tags{
        ul > li {
            border-bottom:none;
            padding-bottom:0;
            @include clean-a;
        }
    }
    .post-meta{
        @include meta-info;
    }
} 
/* -------------------------------- 

File#: 
Title: 
Descr: 
Usage: 

-------------------------------- */
.page-segments {
    .segment-name {
        position:relative;
        &:not(:first-child) {
            margin-top: var(--space-md);
        }
        &:hover:before {
            text-decoration: none;
            color:var(--color-contrast-low);
            content: '#';
            left: -1em;
            position: absolute;
            left: 0%;
            transform: translateX(-100%);
            padding: 0 var(--space-4xs);
        }
    }
    .post-meta{
        @include meta-info;
    }
}
/* -------------------------------- 

File#: _1_post-header
Title: 
Descr: 
Usage: 

-------------------------------- */
.post-header{
    background-color: hsla(var(--color-bg-h),var(--color-bg-s),var(--color-bg-l), .6);
    &__title {
        text-decoration: none;
        color: var(--color-contrast-higher);
        font-size: var(--text-3xl);
        @include max-width(md) {
                font-size: var(--text-lg);
        }
    }
    &__subtitle {
        color: var(--color-contrast-high);
        font-size: var(--text-lg);
        @include max-width(md) {
            font-size: var(--text-sm);
        }
    }
    &__meta {
        @include meta-info;
    }
    &__tag {
        //background-color: hsla(var(--color-bg-h),var(--color-bg-s),var(--color-bg-l), 0.4);
        @include clean-a;
    }
    &__divider {
        display: flex;
        align-items:center;
        span {
            display: block;
            margin: 0 var(--space-xs);
            height: 10px;
            width: 10px;
            transform: rotate(45deg);
            background-color:var(--color-contrast-low);
        }
        &::before, &::after {
            content: "";
            display: block;
            height: 1px;
            width: auto;
            flex-grow: 1;
            background-color: var(--color-contrast-lower);
        }
    }
}