@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _2_common-list
Title: Common List
Descr: Custom list component
Dependencies: 
        _1_list
        _1_badge
        _1_text-background-effects
Usage: https://codyhouse.co/ds/components/info/list

-------------------------------- */

/* #region (_2_common-list) */
.common-list {
    min-width: 200px;
    &[class *="position-sticky"] {
        @include sticky-panel;
    }
    ul {
        li {
            a {
                display: flex;
                justify-content: space-between;
                align-content:center;
                padding: 8px 12px;
                text-decoration: none;
                color:var(--color-contrast-medium);
                transition: background 0.2s;
                &:hover {
                    color:var(--color-contrast-high);
                }
                span{
                    align-self: center;
                }
            }
        }
    }
}
/* #endregion */