@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_popover
Title: Popover
Descr: A pop-up box controlled by a trigger element
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --popover-width: 250px;
  --popover-control-gap: 4px; // ⚠️ use px units - vertical gap between the popover and its control
  --popover-viewport-gap: 20px; // ⚠️ use px units - vertical gap between the popover and the viewport - visible if popover height > viewport height
  --popover-transition-duration: 0.2s;
}
.popover {
  position: fixed; // top/left position set in JS
  width: var(--popover-width);
  z-index: var(--z-index-popover, 5); 
  margin-top: var(--popover-control-gap);
  margin-bottom: var(--popover-control-gap);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s var(--popover-transition-duration), opacity var(--popover-transition-duration);
  &--is-visible {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity var(--popover-transition-duration);
  }
  //&-control--active {
    // class added to the trigger when popover is visible
  //}
}