@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_previous-next-links
Title: Previous/Next Links
Descr: Links to previous/next articles
Usage: https://codyhouse.co/ds/components/info/previous-next-links

-------------------------------- */

.pn-links {
  $this: &;
  border-top: 1px solid var(--color-contrast-lower);
  border-bottom: 1px solid var(--color-contrast-lower);
  @include clean-a;
  &__item:first-child {
      #{$this}__link {
      border-bottom: 1px solid var(--color-contrast-lower);
      }
  }
  &__link {
      display: flex;
      align-items: center;
      height: 100%;
      color: inherit;
      padding: var(--space-md);
      &:hover {
          text-decoration: underline;
          #{$this}__icon {
              line:nth-child(1) {
                  transform: rotate(180deg);
              }
              line:nth-child(2) {
                  transform: rotate(-180deg);
              }
              line:nth-child(3) {
                  stroke-dashoffset: 14;
              }
          }
      }
  }
  &__icon { // animated icon
      flex-shrink: 0;
      line:nth-child(1),
      line:nth-child(2) {
          transition: transform .3s var(--ease-out);
      }
      line:nth-child(1) {
          transform-origin: 42px 19px;
      }
      line:nth-child(2) {
          transform-origin: 42px 29px;
      }
      line:nth-child(3) {
          stroke-dasharray: 48;
          transition: stroke-dashoffset .3s var(--ease-out);
      }
  }
}
@include min-width(md) {
  .pn-links__item:first-child .pn-links__link {
      border-bottom: none;
      border-right: 1px solid var(--color-contrast-lower);
  }
}