@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _3_hiding-nav
Title: 
Descr: 
Usage: https://codyhouse.co/ds/components/info/hiding-navigation
Dependencies: 
    _1_anim-menu-btn Animated Menu Button
    _2_flexy-header Flexi Header
    _1_sub-navigation Sub Navigation

-------------------------------- */

.hide-nav {
    --hide-nav-transition-duration: 0.3s;
    position: sticky !important;
    top: 0;
    will-change: transform;
    transition:transform var(--hide-nav-transition-duration), background-color var(--hide-nav-transition-duration);
    &--fixed {
        background-color:transparent;
    }
    &--has-bg {
        background-color: var(--color-bg);
    }
}