@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_back-to-top
Title: Back to top
Descr: Component that triggers scroll-y to the top of the page
Usage: https://codyhouse.co/ds/components/info/back-to-top

-------------------------------- */
:root {
  --c-progress-bar-size: 40px;
  --c-progress-bar-stroke-width:6;
  --c-progress-bar-stroke-color: var(--color-primary);
  --c-progress-bar-trail-color: hsla(calc(var(--color-primary-h)), var(--color-primary-s), var(--color-primary-l), 0.250);

}
.back-to-top {
  width: var(--c-progress-bar-size);
  height: var(--c-progress-bar-size);
  display: flex;
  position: fixed;
  right: var(--space-md);
  bottom: var(--space-md);
  z-index: var(--z-index-fixed-element, 10); 
  transition: visibility 0s 0.3s, opacity 0.3s, transform 0.3s var(--ease-in-out);
  transform: translateX(10%);
  border-radius: 50%;
  background-color: var(--color-bg);
  box-shadow: var(--inner-glow), var(--shadow-md);
  opacity: 0;
  visibility: hidden;
  @include max-width(md) {
    right: var(--space-3xs);
  }
  &:hover {
    background-color: var(--color-bg-dark);
  }
  .icon {
    display: block;
    margin: auto;
    color: var(--color-contrast-high);
  }
  &--is-visible { 
    transition: visibility 0s, opacity 0.3s, transform 0.3s var(--ease-in-out);
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}
