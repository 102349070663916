@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_anim-menu-btn
Title: Animated Menu Button
Descr: Custom Select with advanced structure options.
Usage: https://codyhouse.co/ds/components/info/animated-menu-button
-------------------------------- */

:root {
    --anim-menu-btn-size: 48px;
    --anim-menu-btn-transition-duration: .2s;
    --anim-menu-btn-icon-size: 32px;
    --anim-menu-btn-icon-stroke:2px;
}
.anim-menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--anim-menu-btn-size);
    height:var(--anim-menu-btn-size);
    &__icon {
        position: relative;
        display: block;
        font-size: var(--anim-menu-btn-icon-size);
        width: 1em;
        height: var(--anim-menu-btn-icon-stroke);
        color: inherit;
        background-image: linear-gradient(currentColor, currentColor);
        background-repeat: no-repeat;
        transform:scale(1);
        &::before, &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-image: inherit;
            border-radius:inherit;
        }
        &--close {
            background-size: 100% 100%;
            will-change: transform, background-size;
            transition-property: transform, background-size;
            transition-duration:var(--anim-menu-btn-transition-duration, 0.2s);
        }
    }
    &:active & {
        &__icon {
            &--close {
                transform:scale(0.9);
            }
        }
    }
    &__icon {
        &--close {
            &::before, &::after {
                will-change: inherit;
                transition:inherit;
            }
            &::before {
                transform:translateY(-0.25em) rotate(0);
            }
            &::after {
                transform:translateY(0.25em) rotate(0);
            }
        }
    }
    &--state-b & {
        &__icon{
            &--close {
                background-size:0% 100%;
                &::before {
                    transform:translateY(0) rotate(45deg);
                }
                &::after {
                    transform:translateY(0) rotate(-45deg);
                }
            }
        }
    }
}
.anim-menu-btn {
    $this: &;
    &__icon {
        &--arrow-left, 
        &--arrow-right, 
        &--arrow-up, 
        &--arrow-down {
            border-radius: 50em;
            will-change: transform;
            transition-property: transform;
            transition-duration:var(--anim-menu-btn-transition-duration, 0.2s);
        }
    }
    &:active {
        #{$this}__icon{
            &--arrow-left, 
            &--arrow-right, 
            &--arrow-up, 
            &--arrow-down {
                transform:scale(.9);
            }
        }
    }
    &__icon {
        &--arrow-left, 
        &--arrow-right, 
        &--arrow-up, 
        &--arrow-down {
            &::before, &::after {
                transform-origin: calc(var(--anim-menu-btn-icon-stroke) / 2) 50%;
                will-change: transform, width;
                transition-property: transform, width;
                transition-duration:var(--anim-menu-btn-transition-duration, 0.2s);
            }
            &::before{
                transform:translateY(-0.25em) rotate(0);
            }
            &::after {
                transform:translateY(0.25em) rotate(0);
            }
        }
        &--arrow-right {
            transform:rotate(180deg);
        }
    }
    &:active {
        #{$this}__icon {
            &--arrow-right{
                transform:rotate(180deg) scale(.9);
            } 
        }
    }
    &--state-b {
        #{$this}__icon {
            &--arrow-left, 
            &--arrow-right, 
            &--arrow-up, 
            &--arrow-down {
                &::before, &::after {
                    width:50%;
                }
                &::before {
                    transform:translateY(0) rotate(-45deg);
                }
                &::after {
                    transform:translateY(0) rotate(45deg);
                }
            }
        }
        &:active{
            #{$this}__icon {
                &--arrow-up {
                    transform:rotate(-90deg) scale(0.9);
                }
                &--arrow-down {
                    transform:rotate(90deg) scale(0.9);
                }
            }
        }
        #{$this}__icon {
            &--arrow-up {
                transform:rotate(-90deg);
            }
            &--arrow-down {
                transform: rotate(90deg);
            }
        }
    }
}

