@charset "utf-8";

@use 'reset';
@use 'config' as * with ( 
  // ← customize the framework
  // https://fontjoy.com combina fonts
  // https://fonts.google.com/share?selection.family=Caveat:wght@400;700%7CIM%2BFell%2BDW%2BPica:ital@0;1%7CRoboto%2BSlab
  $font-family: (
    'primary': 'Roboto Slab, serif',
    'secondary': 'IM Fell DW Pica, serif',
    'tertiary': 'Caveat, cursive'
  )
);
// ↓ copy & modify these templates locally
@use 'typography';
@use 'icons';
@use 'buttons_MEU';
@use 'forms_MEU';

// ← import here the components
@use 'components/1_404_MEU'  as *;
@use 'components/1_anim-menu-btn_MEU'  as *;
@use 'components/1_article-gallery-v3_MEU'  as *;
@use 'components/1_back-to-top'  as *;
@use 'components/1_badge_MEU' as *;
@use 'components/1_code-snippet_MEU'  as *;
@use 'components/1_drop-cap'  as *;
@use 'components/1_foodnotes_MEU'  as *;
@use 'components/1_hero_MEU'  as *;
@use 'components/1_keyboard-input'  as *;
@use 'components/1_list' as *;
@use 'components/1_language-picker'  as *;
@use 'components/1_main-footer_MEU'  as *;
@use 'components/1_note_MEU' as *;
@use 'components/1_pagination_MEU' as *;
@use 'components/1_popover'  as *;
@use 'components/1_previous-next-links_MEU' as *;
@use 'components/1_radios-checkboxes_MEU' as *;
@use 'components/1_smooth-scrolling' as *;
@use 'components/1_social-sharing'  as *;
@use 'components/1_sub-navigation'  as *;
@use 'components/1_switch'  as *;
@use 'components/1_table_MEU'  as *;
@use 'components/1_table-of-contents_MEU' as *;
@use 'components/1_table-v2' as *;
@use 'components/1_text-background-effects' as *;
@use 'components/1_tooltip'  as *;
@use 'components/2_adv-custom-select'  as *;
@use 'components/2_common-list'  as *;
@use 'components/2_copy-to-clip'  as *;
@use 'components/2_flexi-header_MEU'  as *;
@use 'components/2_settings-table' as *;
@use 'components/2_sticky-sharebar'  as *;
@use 'components/3_hiding-nav'  as *;
@use 'components/3_light-dark-switch'  as *;

@use 'util'; // ← utility classes

h1, h2 {
  font-family: var(--font-secondary);
}

h3, h4 {
  font-family: var(--font-tertiary);
}

/* #region (highlight) */
/*!
  Theme: a11y-light & a11y-light
  Author: @ericwbailey
  Maintainer: @ericwbailey
  https://github.com/highlightjs/highlight.js/blob/main/src/styles/a11y-light.css
*/
$colors-highlight: (
  'default': (
    hljs: (
      base: '0, 0%, 33%'
    ),
    hljs-background: (
      base: '0, 21%, 89%'
    ),
    hljs-comment: (
      base: '0, 0%, 41%'
    ),
    hljs-variable: (
      base: '2, 80%, 47%'
    ),
    hljs-number: (
      base: '33, 100%, 33%'
    ),
    hljs-attribute: (
      base: '33, 100%, 33%'
    ),
    hljs-string: (
      base: '120, 100%, 25%'
    ),
    hljs-title: (
      base: '195, 100%, 33%'
    ),
    hljs-keyword: (
      base: '280, 60%, 39%'
    )
  ),
'dark': (
    hljs: (
      base: '60, 30%, 96%'
    ),
    hljs-background: (
      base: '0, 0%, 17%'
    ),
    hljs-comment: (
      base: '54, 32%, 75%'
    ),
    hljs-variable: (
      base: '17, 100%, 74%'
    ),
    hljs-number: (
      base: '37, 91%, 58%'
    ),
    hljs-attribute: (
      base: '51, 100%, 50%'
    ),
    hljs-string: (
      base: '80, 75%, 55%'
    ),
    hljs-title: (
      base: '180, 100%, 44%'
    ),
    hljs-keyword: (
      base: '291, 30%, 83%'
    )
  )
) !default;

@each $theme, $colors-highlight-array in $colors-highlight {
  $theme-selector: '[data-theme=#{$theme}]';
  @if $theme == 'default' {
    $theme-selector: ':root, [data-theme="default"]';
  }

  #{$theme-selector} {
    @each $main-color, $variation-array in $colors-highlight-array {
      @each $variation, $hsl in $variation-array {
        $appendix: #{'-'+$variation};
        @if $variation == 'base' {
          $appendix: '';
        }
        $list: get-hsl-values($hsl);
        --color-#{$main-color}#{$appendix}: hsl(#{$hsl});
      }
    }
  }
}
/* #endregion */