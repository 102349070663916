@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_sub-navigation
Title: Sub Navigation
Descr: Secondary navigation template.
Usage: https://codyhouse.co/ds/components/info/sub-navigation

-------------------------------- */

.subnav {
    $this: &;
    &__nav {
        position: relative;
        display:flex;
        &::after {
            content: "";
            width: 1em;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background: linear-gradient(to right, hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), 0), hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), 1));
            pointer-events:none;
        }
    }
    &__list {
        display: flex;
        overflow:auto;
    }
    &__item {
        display: inline-block;
        flex-shrink:0;
    }
    &__link {
        display: block;
        font-size: var(--text-sm);
        padding: var(--space-xs) var(--space-sm);//1.5625em;
        text-decoration: none;
        color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), 0.65);
        border: 3px solid transparent;
        border-right-width: 0;
        border-left-width: 0;
        transition:.2s;
        &:hover {
            color:var(--color-contrast-high);
        }
        &[aria-current=page] {
            border-bottom-color: var(--color-primary);
            color:var(--color-contrast-high);
        }
    }
    &:not(&--collapsed) &__control, 
    &:not(&--collapsed) &__close-btn {
        display:none;
    }
    &--collapsed {
        display:inline-block;
        #{$this}__wrapper {
            position: fixed;
            display: block;
            z-index: var(--z-index-overlay, 15);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.9);
            visibility: hidden;
            opacity:0;
            &--is-visible {
                visibility: visible;
                opacity: 1;
                transition:opacity .3s;
                #{$this}__nav {
                    transform: translateY(0);
                    transition:transform .3s;
                }
            }
        }
        #{$this}__nav {
            display: block;
            background-color: var(--color-bg);
            box-shadow: var(--shadow-md);
            max-height: 100%;
            overflow: auto;
            transform:translateY(-1em);
            &::after {
                display:none;
            }
        }
        #{$this}__list {
            flex-direction: column;
            overflow: visible;
            padding:0 var(--space-md) var(--space-md);
        }
        #{$this}__link {
            border-width: 0;
            font-size: var(--text-md);
            padding:var(--space-xs) 0;
            &[aria-current=page] {
                color:var(--color-primary);
            }
        }
    }
    &__close-btn {
        --size: 2em;
        width: var(--size);
        height: var(--size);
        display: flex;
        margin: var(--space-xs) var(--space-xs) 0 auto;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: var(--color-bg-light);
        box-shadow: var(--inner-glow), var(--shadow-sm);
        transition:.2s;
        &:hover {
            background-color: var(--color-bg-lighter);
            box-shadow:var(--inner-glow), var(--shadow-md);
        }
        .icon {
            display: block;
            color:var(--color-contrast-high);
        }
    }
}

[class *=subnav--expanded]::before {
    display: none;
    content: "collapsed";
}
.subnav {
    @include min-width(xs)  {
        &--expanded\@xs::before {
            content: "expanded";
        }
    }
    @include min-width(sm) {
        &--expanded\@sm::before {
            content: "expanded";
        }
    }
    
    @include min-width(md) {
        &--expanded\@md::before {
            content: "expanded";
        }
    }
    
    @include min-width(lg)  {
        &--expanded\@lg::before {
            content: "expanded";
        }
    }
    
    @include min-width(xl) {
        &--expanded\@xl::before {
            content: "expanded";
        }
    }
}
