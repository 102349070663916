@use 'config' as *;
// --- 👆 update this path if you're importing CodyFrame as npm module
// e.g., '../../../node_modules/codyframe/main/scss/config'

.btn {
  $this: &;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  background: var(--color-bg-dark);
  padding: var(--space-2xs) var(--space-sm);
  border-radius: var(--radius-md);
  font-size: 1em;
  font-weight: 500;
  color: var(--color-contrast-higher);
  line-height: 1.2;
  cursor: pointer;
  transition: 0.2s;
  will-change: transform;
  &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 2px var(--color-bg), 
                0 0 0 4px alpha(var(--color-contrast-higher), 0.15);
  }
  &:active {
      transform: translateY(2px);
  }
  /* .btn .btn--primary  & .btn .btn--primary-subtle */
  &#{$this}--primary {
      background: var(--color-primary);
      box-shadow: inset 0 1px 0 alpha(var(--color-white), 0.15), 
                  0 1px 3px alpha(var(--color-primary-darker), 0.25), 
                  0 2px 6px alpha(var(--color-primary-darker), 0.1), 
                  0 6px 10px -2px alpha(var(--color-primary-darker), 0.25);
      color: var(--color-white);
      &:hover {
          background: var(--color-primary-light);
          box-shadow: inset 0 1px 0 alpha(var(--color-white), 0.15), 
                      0 1px 2px alpha(var(--color-primary-darker), 0.25), 
                      0 1px 4px alpha(var(--color-primary-darker), 0.1), 
                      0 3px 6px -2px alpha(var(--color-primary-darker), 0.25);
      }
      &:focus-visible {
          box-shadow: inset 0 1px 0 alpha(var(--color-white), 0.15), 
                      0 1px 2px alpha(var(--color-primary-darker), 0.25), 
                      0 1px 4px alpha(var(--color-primary-darker), 0.1), 
                      0 3px 6px -2px alpha(var(--color-primary-darker), 0.25), 
                      0 0 0 2px var(--color-bg), 
                      0 0 0 4px var(--color-primary);
      }
      &-subtle {
          background: alpha(var(--color-primary), 0.15);
          color: var(--color-primary);
          &:hover {
              background: alpha(var(--color-primary), 0.12);
          }
          &:focus-visible {
              box-shadow: 0 0 0 2px var(--color-bg), 
                          0 0 0 4px var(--color-primary);
          }
      }
  }
  /* .btn .btn--subtle */
  &#{$this}--subtle {
      background: var(--color-bg-lighter);
      color: var(--color-contrast-higher);
      box-shadow: inset 0 1px 0 alpha(var(--color-white), 0.1), 
                  0 0 0 1px alpha(var(--color-black), 0.02), 
                  0 0.3px 0.4px alpha(var(--color-black), 0.025),
                  0 1px 3px -1px alpha(var(--color-black), 0.2), 
                  0 3.5px 6px alpha(var(--color-black), 0.12);
      &:hover {
          background: var(--color-bg-light);
          box-shadow: inset 0 1px 0 alpha(var(--color-white), 0.1), 
                      0 0 0 1px alpha(var(--color-black), 0.02), 
                      0 0.1px 0.3px alpha(var(--color-black), 0.06),
                      0 1px 2px alpha(var(--color-black), 0.12),
                      0 1px 3px -1px alpha(var(--color-black), 0.2);
      }
      &:focus-visible {
          box-shadow: inset 0 1px 0 alpha(var(--color-white), 0.1), 
                  0 0 0 1px alpha(var(--color-black), 0.02), 
                  0 0.3px 0.4px alpha(var(--color-black), 0.025),
                  0 1px 3px -1px alpha(var(--color-black), 0.2), 
                  0 3.5px 6px alpha(var(--color-black), 0.12), 
                  0 0 0 2px var(--color-bg), 
                  0 0 0 4px var(--color-contrast-high);
      }
  }
  /* .btn .btn--accent */
  &#{$this}--accent{
      background: var(--color-accent);
      color: var(--color-white);
      box-shadow: inset 0 1px 0 alpha(var(--color-white), 0.15), 
                  0 1px 3px alpha(var(--color-accent-darker), 0.25), 
                  0 2px 6px alpha(var(--color-accent-darker), 0.1), 
                  0 6px 10px -2px alpha(var(--color-accent-darker), 0.25);
  
      &:hover {
          background: var(--color-accent-light);
          box-shadow: inset 0 1px 0 alpha(var(--color-white), 0.15), 
                      0 1px 2px alpha(var(--color-accent-darker), 0.25), 
                      0 1px 4px alpha(var(--color-accent-darker), 0.1), 
                      0 3px 6px -2px alpha(var(--color-accent-darker), 0.1);
      }
  
      &:focus-visible {
          box-shadow: inset 0 1px 0 alpha(var(--color-white), 0.15), 
                      0 1px 2px alpha(var(--color-accent-darker), 0.25), 
                      0 1px 4px alpha(var(--color-accent-darker), 0.1), 
                      0 3px 6px -2px alpha(var(--color-accent-darker), 0.1), 
                      0 0 0 2px var(--color-bg), 
                      0 0 0 4px var(--color-accent);
      }
      &-subtle {
          background: alpha(var(--color-accent), 0.15);
          color: var(--color-accent);
          &:hover {
              background: alpha(var(--color-accent), 0.12);
          }
          &:focus-visible {
              box-shadow: 0 0 0 2px var(--color-bg), 
                          0 0 0 4px var(--color-accent);
          }
      }
  }
  /* .btn .btn--contrast */
  &#{$this}--contrast {
      background: alpha(var(--color-contrast-higher), 0.95);
      color: var(--color-bg);
      box-shadow: 0 0.1px 0.3px alpha(var(--color-black), 0.06),
                  0 1px 2px alpha(var(--color-black), 0.12); 
      &:hover {
          background: var(--color-contrast-higher);
      }
      &:focus-visible {
          box-shadow: 0 0.1px 0.3px alpha(var(--color-black), 0.06),
                      0 1px 2px alpha(var(--color-black), 0.12),
                      0 0 0 2px var(--color-bg), 
                      0 0 0 4px alpha(var(--color-contrast-higher), 0.95);
      }
  }
  &#{$this}--disabled, 
  &[disabled],
  &[readonly] {
      opacity: 0.6;
      cursor: not-allowed;
  }
  /* a button containing (only) an icon */
  &#{$this}--icon {
      padding: var(--space-2xs);
  }
}