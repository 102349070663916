@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_note
Title: Note
Descr: A brief piece of information given to the user.
default
--warning
--error
--success

Usage: https://codyhouse.co/ds/components/info/note

-------------------------------- */
%note {
    background-color: var(--color-bg-light);
    border-left: 3px solid var(--color-primary);
    padding: var(--space-sm);
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
    box-shadow:var(--shadow-sm);
    display:flex;
    align-items: center;
    svg{
        margin-right: var(--space-sm);
    }
    p{ 
        --heading-line-height: 1.2;
        --body-line-height: 1.4;
        --spacing: 1rem;
        line-height: var(--body-line-height);
    }
}
.note, 
.text-component .note{
    &--info{
        @extend %note;
    }
    &--warning {
        @extend %note;
        border-left-color:var(--color-warning);
    }
    &--error {
        @extend %note;
        border-left-color:var(--color-error);
    }
    &--success {
        @extend %note;
        border-left-color:var(--color-success);
    }
    &__content > * :last-child {
        margin-bottom:0;
    }
    &__title {
        margin-bottom: 0;
    }
}