@use 'config' as *;
// --- 👆 update this path if you're importing CodyFrame as npm module
// e.g., '../../../node_modules/codyframe/main/scss/config'

.icon {
    --size: 1em;
    font-size: var(--size);
    height: 1em;
    width: 1em;
    display: inline-block;
    color: inherit;
    fill: currentColor;
    line-height: 1;
    flex-shrink: 0;
    max-width: initial;
    &--3xs {
        --size: 8px;
    }
    &--2xs {
        --size: 12px;
    }
    &--xs {
        --size: 16px;
    }
    &--sm {
        --size: 24px;
    }
    &--md {
        --size: 32px;
    }
    &--lg {
        --size: 48px;
    }
    &--xl {
        --size: 64px;
    }
    &--2xl {
        --size: 96px;
    }
    &--3xl {
        --size: 128px;
    }
    &--is-spinning { 
        /* gira la icona infinitament */
        animation: icon-spin 1s infinite linear;
    }
    use {
        /* SVG symbols - habilita les correccions de color de les icones */
        color: inherit;
        fill: currentColor;
    }
}
@keyframes icon-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}