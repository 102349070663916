@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_keyboard-input
Title: 
Descr: 
Usage: 

-------------------------------- */

kbd {
  background-color: var(--color-bg);
  padding: var(--space-4xs) var(--space-3xs);
  border-radius: var(--radius-md);
  box-shadow: inset 0 0 0 1px alpha(var(--color-contrast-higher), 0.15);
  color: var(--color-contrast-medium);
}