@use '../config' as *;
// --- ↑ update this path if you're importing CodyFrame as npm module

/* -------------------------------- 

File#: _1_code-snippet @MEU
Title: Code Snippet
Descr: A block of preformatted text, containing computer code
Usage: https://codyhouse.co/ds/components/info/code-snippet

-------------------------------- */

code, code.hljs {
  background-color: var(--color-bg-dark);
  font-family: monospace;
  padding: var(--space-4xs) var(--space-3xs);
  border-radius: var(--radius-md);

  &::selection {
    background-color: var(--color-contrast-high);
    color: var(--color-bg);
  }
}


.code-snippet {
  background-color: var(--color-bg-dark);
  border-radius: var(--radius-md);
  white-space-collapse: collapse;
  code, code.hljs {
    position:relative;
    //top: -30px; //copy2clip size
    background-color: transparent;
    line-height: 1.45;
    font-size: var(--text-sm);
    display: block;
    overflow-wrap: anywhere;
    overflow-x: auto;
    padding: var(--space-sm);
    white-space: pre-wrap;
  }
}
/* #region (_2_code-highlight) */
@import url(https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/styles/default.min.css);
  /*
  Theme: a11y-light
  Author: @ericwbailey
  Maintainer: @ericwbailey

  Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css
  */

.hljs {
    background: var(--color-hljs-background); 
    color:var(--color-hljs) !important;
}

/* Comment */
.hljs-comment,
.hljs-quote {
    color:var(--color-hljs-comment) !important;
}

.hljs-variable,
.hljs-template-variable,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-deletion {
    color:var(--color-hljs-variable) !important;
}

.hljs-number,
.hljs-built_in,
.hljs-literal,
.hljs-type,
.hljs-params,
.hljs-meta,
.hljs-link {
    color:var(--color-hljs-number) !important; 
}

.hljs-attribute {
    color:var(--color-hljs-attribute) !important; 
}

.hljs-string,
.hljs-symbol,
.hljs-bullet,
.hljs-addition {
color:var(--color-hljs-string); 
}

.hljs-title,
.hljs-section {
    color:var(--color-hljs-title) !important; 
}

.hljs-keyword,
.hljs-selector-tag {
    color:var(--color-hljs-keyword) !important; 
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: bold;
}

@media screen and (-ms-high-contrast: active) {
.hljs-addition,
.hljs-attribute,
.hljs-built_in,
.hljs-bullet,
.hljs-comment,
.hljs-link,
.hljs-literal,
.hljs-meta,
.hljs-number,
.hljs-params,
.hljs-string,
.hljs-symbol,
.hljs-type,
.hljs-quote {
        color: highlight;
    }

    .hljs-keyword,
    .hljs-selector-tag {
        font-weight: bold;
    }
}
.highlighter-rouge .copy-to-clip {
    margin: .3rem;
    padding: .3rem;
}

/* fix wrong badge display for firefox browser */
code > table pre::before {
    display: none;
}
/* #endregion */



